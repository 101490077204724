import React from "react"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav } from "react-bootstrap"
import Scroller from "./scroller"
// import logo from "../images/svg/logo_gr.svg";

export default class Headermin extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  render() {
    return (
      <>
        <header>
          <Navbar
            className="navbar navbar-expand-lg navbar-light fixed-top py-3"
            id="mainNav"
            expand="lg"
            collapseOnSelect={true}
          >
            <div className="container">
              <a
                className="navbar-brand js-scroll-trigger logo"
                href="/"
                // onClick={Scroller.handleAnchorScroll}
              ></a>
              <Navbar.Toggle aria-controls="navbarResponsive" />
              <Navbar.Collapse id="navbarResponsive"></Navbar.Collapse>
            </div>
          </Navbar>
        </header>
      </>
    )
  }
}
