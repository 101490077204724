import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import CookieConsent from "react-cookie-consent"
import Headermin from "../components/headermin"

export default class ImpressumPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0,
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this)
    this.setModal = this.setModal.bind(this)
  }

  handlePortfolioClick(index, e) {
    e.preventDefault()
    this.setModal(true, index)
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <CookieConsent
          location="bottom"
          buttonText="Verstanden!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "$orange" }}
          buttonStyle={{
            color: "$brown",
            fontFamily: "$font-family-sans-serif",
            fontSize: "13px",
          }}
          expires={150}
        >
          Diese Webseite verwendet nur technisch notwendige Cookies.{" "}
        </CookieConsent>
        <Headermin />

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 py-4 heig">
                <h1 className="mt-0 pb-4">Impressum</h1>
                <h2 className="mt-0 pb-2">Angaben gemäß § 5 TMG:</h2>
                <h4>Zeltverleih Alois Markett GbR</h4>
                <p>
                  Friedenstraße 8 b<br /> 46485 Wesel <br />
                  USt-IdNr: DE174658737
                  <br />
                  <br />
                  Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                  <br /> Alois Markett
                </p>
                <div className="vh-50"></div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
